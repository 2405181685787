.logo {
  height: 8vh;
  position: absolute;
  top: 99vh;
  left: 50%;
  transform: translate(-50%, -100%);
}

.fixed-slogan {
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
}

/* 70vh available */
/* 55vh for main
 * 15vh for control
 */
.timer {
  position: relative;
  margin: 0;
  height: 100vh;
  padding-top: 20vh;
  padding-bottom: 10vh;
  text-align: left;
}

/* unused */
/* 10vh */
.info-bar {
  margin: 2vh auto;
  height: 6vh;
  line-height: 6vh;
  width: fit-content;
  background: #ebd3ab;
  border-radius: 0.5rem;
}

/* unused */
/* 20vh */
.title {
  height: 20vh;
  padding: 5vh;
  /* text-align: center; */
}

.main { 
  height: 50vh;
}

.vertical-text {
  writing-mode: vertical-rl;
}

.team-name {
  color: #27539b;
}

/* 15vh */
.control {
  height: 15vh;
  margin: 2.5vh auto;
  text-align: center;
}

.control button {
  margin: 0 3vw;
  padding: 0;
}

.control svg {
  font-size: 10vh;
}

.setting {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}