.logo {
  height: 10vh;
  position: absolute;
  right: 1rem;
  top: 0rem;
}

/* 70vh available */
.timer {
  margin: 0;
  height: 100vh;
  padding-top: 25vh;
  padding-bottom: 5vh;
  text-align: left;
}

/* 10vh */
.info-bar {
  margin: 2vh auto;
  height: 6vh;
  line-height: 6vh;
  width: fit-content;
  background: #ebd3ab;
  border-radius: 0.5rem;
}

/* 20vh */
.title {
  height: 20vh;
  padding: 5vh;
  /* text-align: center; */
}

/* 30 + 10 + 20vh */
.main { 
  height: 60vh;
}

.vertical-text {
  writing-mode: vertical-rl;
}

.team-name {
  color: #27539b;
}

/* 5vh */
.control {
  height: 5vh;
  text-align: center;
}

.control button {
  margin: 0 3vw;
  padding: 0;
}

.control svg {
  font-size: 10vh;
}

.setting {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}